import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import {
  CardContent, Typography, Card, FormControl, FormGroup, FormControlLabel, CardActions, Button, FormHelperText,
  useTheme, useMediaQuery, Box,
} from '@mui/material'
import GreenCheckbox from '../_library/GreenCheckbox'
import { Trans, useTranslation } from 'react-i18next'


const PREFIX = 'IntroTest'

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  underline: `${PREFIX}-underline`,
  input: `${PREFIX}-input`,
  error: `${PREFIX}-error`,
  genderButton: `${PREFIX}-genderButton`,
  genderSymbol: `${PREFIX}-genderSymbol`,
  buttonSection: `${PREFIX}-buttonSection`,
}

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: 'White',
    color: 'Black',
    maxWidth: '800px',
    height: 'auto',
    margin: '10rem auto',
    marginTop: '4em',
    padding: '4em',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      margin: '0.5rem',
      padding: '1rem',
    },
  },
  
  [`& .${classes.title}`]: {
    fontSize: '1.5em',
    fontWeight: '200',
    color: 'black',
    marginBottom: '2.5rem',
    '& strong': {
      color: 'Black',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  
  [`& .${classes.underline}`]: {
    textDecoration: 'underline',
  },
  
  [`& .${classes.input}`]: {
    fontWeight: 'bold',
    margin: '0.5rem 0',
  },
  
  [`& .${classes.error}`]: {
    color: theme.palette.primary.pink,
  },
  
  [`& .${classes.buttonSection}`]: {
    padding: 'auto',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
}))

const IntroTest = ({ onSubmit }) => {
  
  const theme = useTheme()
  const isDownSm = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  
  const [phone, setPhone] = useState(false)
  const [facebook, setFacebook] = useState(false)
  const [calm, setCalm] = useState(false)
  const [setup, setSetup] = useState(false)
  const [error, setError] = useState(null)
  
  const setGenderAndSubmit = gender => {
    if (phone && facebook && calm && setup){
      onSubmit(gender)
    }
    else setError(true)
  }
  
  return (
    <StyledCard className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color='textSecondary'
          gutterBottom
        >
          <Trans i18nKey='test.intro-caption'>
            Pour obtenir des résultats fiables on vous recommande de répondre aux 128 propositions <b>dans un contexte de vie perso </b>et avec le plus de spontanéité possible.
          </Trans>
        </Typography>
        <FormControl
          required
          component='fieldset'
          sx={{ pl: 1 }}
        >
          <FormGroup>
            <FormControlLabel
              control={(
                <GreenCheckbox
                  checked={phone}
                  onChange={e => setPhone(e.target.checked)}
                  value='phone'
                />
              )}
              label={t('test.intro-silence-check')}
              className={classes.input}
            />
            <FormControlLabel
              control={(
                <GreenCheckbox
                  checked={facebook}
                  onChange={e => setFacebook(e.target.checked)}
                  value='facebook'
                />
              )}
              label={t('test.intro-mailbox-check')}
              className={classes.input}
            />
            <FormControlLabel
              control={(
                <GreenCheckbox
                  checked={calm}
                  onChange={e => setCalm(e.target.checked)}
                  value='calm'
                />
              )}
              label={t('test.intro-calm-check')}
              className={classes.input}
            />
            <FormControlLabel
              control={(
                <GreenCheckbox
                  checked={setup}
                  onChange={e => setSetup(e.target.checked)}
                  value='setup'
                />
              )}
              label={t('test.intro-breathe-check')}
              className={classes.input}
            />
          </FormGroup>
          {error && (
            <FormHelperText className={classes.error}>
              <Trans i18nKey='test.intro-error'>
                Toutes les cases doivent être cochées
              </Trans>
            </FormHelperText>
          )}
        </FormControl>
      </CardContent>
      <CardActions className={classes.buttonSection} disableSpacing={isDownSm}>
        <GenderBtn onClick={() => setGenderAndSubmit('woman')}>
          <Box component='span' aria-label='woman' sx={{ fontSize: '1.5rem', pr: 1 }}></Box>&nbsp;{t('test.intro-woman-ready')}
        </GenderBtn>
        <GenderBtn onClick={() => setGenderAndSubmit('man')} sx={{ mt: isDownSm && '5px' }}>
          <Box component='span' aria-label='man' sx={{ fontSize: '1.5rem', pr: 1 }}></Box>&nbsp;{t('test.intro-man-ready')}
        </GenderBtn>
      </CardActions>
    </StyledCard>
  )
}

// eslint-disable-next-line react/prop-types
const GenderBtn = ({ children, ...props }) => <Button
  {...props}
  size='large'
  sx={{
    backgroundColor: 'grey.150',
    color: 'black',
    fontWeight: 'bold',
    width: { xs: '100%', md: '10rem' },
    textTransform: 'none',
    margin: { md: '0 2rem' },
  }}
>{children}</Button>

IntroTest.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default IntroTest
